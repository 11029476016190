import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, MyHttpApi, PrivilegeItem, StorageUnit } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedCorporation } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

@autoinject
export class PosStorageUnitList {
  private fieldList: FieldSpec[] = [
    { key: "name", header: "common.name", type: "text", },
    { key: "value", header: "common.value", type: "number", },
    { key: "baseUnit", header: "storageUnit.baseUnit", type: "enum", enum: "StorageBaseUnit", },
  ];
  private itemList: StorageUnit[] = [];
  private privilege?: PrivilegeItem;
  private isMaster = false;
  private clients: { [key: number]: Client; } = {};
  private businessGroups: { [key: number]: BusinessGroup; } = {};

  constructor(private api: MyHttpApi, private router: Router, private notify: Notify, private i18n: I18N) {
  }

  async activate(params: { isMaster: string; }) {
    let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
    this.isMaster = params.isMaster === "true";
    this.clients = clients;
    this.businessGroups = businessGroups;
    if (!this.isMaster) {
      this.privilege = getSelectedCorporation();
      this.fieldList = [...this.fieldList,
      { key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
      { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
      { key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
      ];
    }
    await this.search();
  }

  rowCall(key: string, row?: StorageUnit) {
    if (this.privilege?.type === 'CLIENT' && !row?.id && !this.isMaster) {
      const bgId = this.clients[this.privilege.id].businessGroupId || 0;
      if (bgId && !this.businessGroups[bgId].clientSpecificStorageUnits) {
        this.notify.info("businessGroupRestriction.notAllowed", { element: this.i18n.tr(`businessGroupRestriction.storageUnit`) });
        return;
      }
    }
    this.router.navigateToRoute(this.editUrl, { id: row?.id, isMaster: this.isMaster });
  }

  async search() {
    if (!this.isMaster && !this.privilege) {
      return;
    }
    const itemList = await this.api.storageUnitList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.deleteTime);
  }


  @computedFrom("router.currentInstruction.config.navModel.config.name")
  get editUrl() {
    let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
    return routeName.replace(/-list$/, "-edit");
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.search();
    return "";
  }
}
