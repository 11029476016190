import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, VatCategory } from 'utils/api';

@autoinject
export class PosVatCategoryList {
  private fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "name", header: "common.name", type: "text" },
  ];
  private itemList: VatCategory[] = [];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    const itemList = await this.api.vatCategoryList();
    this.itemList = itemList.filter(x => !x.deleteTime);
  }

  rowCall(key: string, row?: VatCategory) {
    this.router.navigateToRoute("admin/pos/vat-category-edit", { id: row?.id });
  }
}
