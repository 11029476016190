import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, MyHttpApi, Printer, StationUpdateRequest, UuidName } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';

@autoinject
export class PosStationEdit {
  private station: StationUpdateRequest = {
    areaId: "",
    name: "",
    billPrinterId: "",
    type: "CLIENT",
    delete: false,
  };
  private clientId?: number;
  private typeAndName = "";
  private areaList: Area[] = [];
  private selectedPaymentTerminalClients: UuidName[] = [];
  private printerList: Printer[] = [];

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate(params: { id?: string; clientId?: number }) {
    if (params.id) {
      let station = await this.api.stationByIdWithPaymentTerminals({ id: params.id });
      this.station = {
        ...station.station,
        delete: !!station.station.deleteTime,
        type: 'CLIENT'
      };
      this.selectedPaymentTerminalClients = station.paymentTerminalClientIds;
      this.clientId = privilegeItemFromElement(station.station)?.id;
    }
    if (!params.id) {
      this.clientId = getSelectedClient() || params.clientId;
    }

    this.typeAndName = await clientIdToTypeAndName(this.api, this.i18n, this.clientId);
    [ this.areaList, this.printerList ] = await Promise.all([
      this.api.areaList({ id: this.clientId }),
      this.api.printerList({ id: this.clientId }),
    ]);
  }

  async save(deleted: boolean) {
    if(!this.clientId) {
      return;
    }
    await this.api.stationUpdate({
      ...this.station,
      privilegeId: this.clientId,
      type: "CLIENT",
      delete: deleted,
    });
    this.router.navigateBack();
  }
}
